import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "developer.jpg" }) {
      ...heroImage
    }
  }
`
const reactDevelopmentPage = () => (
  <Layout>
    <SEO
      title="React Development in Brighton | amillionmonkeys"
      description="Looking for a friendly React developer in Brighton or London? It's our bread and butter. Get in touch to see how we can help you."
    />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <Hero image={data.hero.childImageSharp.fluid}>
            <div className="max-w-md m-auto">
              <p className="text-3xl text-white text-center  font-serif text-shadow">
                At amillionmonkeys we build complex user interfaces and applications using React.
              </p>
            </div>
          </Hero>
          <div className="bg-react bg-stamp text-300 text-white py-16  px-4  pb-24 ">
            <div className=" text-2xl font-serif font-light text-center">
              <p className="mb-4 max-w-lg m-auto">
                <strong>
                  Our apps are always robust, fast and scalable. Each day thousands of people use apps we've built in
                  their work and home. Here are a few examples
                </strong>
              </p>
              <ul className="md:flex list-reset text-base max-w-2xl m-auto">
                <li className="p-4">
                  <h3 className="font-impact text-4xl ">Samsung</h3>
                  <p>
                    In 2019 we built a component library for Samusng using Storybook. This allowed their developers to
                    focus on their API while we developed the front-end in isolation.
                  </p>
                </li>
                <li className="p-4">
                  <h3 className="font-impact text-4xl ">Pinx</h3>
                  <p>
                    Launched in 2017, Pinx was our first adventure in the appstore. The simple application displays
                    user's pins in a user-friendly, ad-free environment.
                  </p>
                </li>
                <li className="p-4">
                  <h3 className="font-impact text-4xl ">CITV</h3>
                  <p>
                    In 2018 we were approached by CITV to build a new microsite for Robozuna. The result may well have
                    been ITV's first progressive web app.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-grey-lighter bg-stamp text-300 text-grey-darkest py-16  px-4  pb-24 ">
            <div className="m-auto max-w-lg text-2xl font-serif font-light text-center">
              <h2 className="font-impact  text-4xl">Let's talk</h2>
              <p>Whether you've got an idea, a problem, or just need another pair of hands, we'd love to talk.</p>
              <Link
                to="/contact"
                className="font-impact  text-3xl text-react border-react border-2 p-3 text-lg inline-block my-4"
              >
                Get in touch
              </Link>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default reactDevelopmentPage
